import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"MenuGeneralMovilCont  pl-10 pr-10"},[_c('div',{staticClass:"MenuGeneralSn d-flex justify-space-between align-center alugn-content-center"},[_c('div',{staticClass:"ButtonMenuCont d-flex justify-center align-center"},[_c(VBtn,{attrs:{"fab":"","small":"","text":"","depressed":""},on:{"click":function($event){_vm.SideMenu = !_vm.SideMenu}}},[_c(VIcon,[_vm._v("mdi-menu")])],1)],1)]),_c(VNavigationDrawer,{staticStyle:{"z-index":"1000000"},attrs:{"right":"","absolute":"","temporary":""},model:{value:(_vm.SideMenu),callback:function ($$v) {_vm.SideMenu=$$v},expression:"SideMenu"}},[_c(VList,{attrs:{"dense":""}},[_c(VListItem,{attrs:{"link":""},on:{"click":function($event){return _vm.redirigir('Home')}}},[_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-view-dashboard")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" Menu link ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }